<template>
  <section class="public-component-usualLive">
    <div class="main">
      <div class="main-header">
        <div class="router2-name">日志列表</div>
      </div>
      <div class="table">
        <el-table default-expand-all height="100%" row-key="id" :data="table.data" v-loading="table.loading">
          <el-table-column prop="index" label="序号"  type="index"></el-table-column>
          <el-table-column prop="username" label="操作账号"></el-table-column>
          <el-table-column prop="role_name" label="所属单位">
            <template slot-scope="scope">{{ scope.row.role_name || '-' }}</template>
          </el-table-column>
          <el-table-column prop="behavior" label="行为">
            <template slot-scope="scope">{{ scope.row.behavior || '-' }}</template>
          </el-table-column>
          <el-table-column prop="method" label="详情">
            <template slot-scope="scope">{{ scope.row.method || '-' }}</template>
          </el-table-column>
          <el-table-column prop="optime" label="操作时间">
            <template slot-scope="scope">{{ scope.row.optime || '-' }}</template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="table.query.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="table.query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="table.total"
      ></el-pagination>
    </div>
  </section>
</template>

<script>
import Public from '@/api/public';
export default {
  // 常住居民名单
  name: 'usualLive',
  props: {},
  components: {},
  data() {
    return {
      componentName: 'randomSelect',
      table: {
        data: [],
        query: {
          keyword: '',
          page: 1,
          size: 100,
        },
        loading: false,
        total: 0,
      },
    };
  },
  methods: {
    // 每页条数变化
    handleSizeChange(data) {
      this.table.query.size = data;
      this.page = 1;
      return this.getLog();
    },
    // 当前页数变化
    handleCurrentChange(data) {
      if (data === this.table.query.page) {
        return;
      } else {
        this.table.query.page = data;
        return this.getLog();
      }
    },
    reset() {
      let { page, size, keyword } = this.table.query;
      if (page === 1 && size === 10 && !keyword) {
        return;
      }
      this.table.query = {
        page: 1,
        size: 10,
        keyword: '',
      };
      return this.getLog();
    },
    search() {
      if (!this.table.query.keyword) {
        return;
      }
      return this.getLog();
    },
    getLog() {
      this.table.loading = true;
      Public.logList(this.table.query)
        .then((res) => {
          this.table.data = res.items;
          this.table.total = res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
  },
  mounted() {},
  created() {
    return this.getLog();
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.public-component-usualLive {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  .main {
    background-color: #fff;
    flex: 1;
    border-bottom: 1px solid #ccc;
    position: relative;
    box-sizing: border-box;
    padding-left: 20px;
    display:flex;
    flex-direction: column;
    .main-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      .router2-name {
        color: $h4;
        padding: 15px 0;
        font-weight: bold;
      }
      .el-button {
        margin-right: 20px;
      }
    }

    .table {
      flex:1;
      width: calc(100% - 20px);
      box-sizing: border-box;
      position: relative;
      padding-bottom: 50px;
      display: flex;
      .el-table {
        border: 1px solid rgba(232, 232, 232, 1);
        border-bottom: none;
        flex: 1;

        .header-bgc {
          background-color: $border4;
        }
      }
    }
    .el-pagination {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
::v-deep .el-dialog__title {
  font-weight: bold;
}
</style>
